import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCreateSearchContext } from '../../contexts/createSearchContext';
import { generateId } from '../../flayr-components';
import useBackendApi from '../../hooks/useBackendApi';
import useQuoteDataStore from '../../hooks/useQuoteDataStore';

interface ISearchFormContainerProps {
  component?: React.ComponentType<React.PropsWithChildren<any>>;
  externalProps?: any;
  fetchFormSettingsOnLoad?: boolean;
  fetchFormSettingsOnInputChange?: boolean;
  searchId?: string;
  children?: any;
  onSubmit?: (e: any) => void;
}

const SearchFormContainer: React.FC<ISearchFormContainerProps> = ({
  component: Component,
  fetchFormSettingsOnLoad,
  fetchFormSettingsOnInputChange,
  searchId,
  externalProps = {},
  onSubmit,
  children,
}) => {
  const {
    userInput,
    store,
    category,
    dataFetched,
    setUserInput,
    fetchFormSettings,
    landingPage,
    heapId,
  } = useCreateSearchContext();
  const { quoteData, resetQuoteData } = useQuoteDataStore();
  const { post } = useBackendApi();
  const history = useHistory();

  React.useEffect(() => {
    if (fetchFormSettingsOnLoad) {
      let params: any = {};
      if (searchId) {
        params.search = searchId;
      }

      fetchFormSettings?.(params);
    }
  }, [searchId, fetchFormSettingsOnLoad]);

  React.useEffect(() => {
    const { location, eventDate, finishTime, services } = userInput;
    const hasSearchFormChanged =
      location?.coordinates?.length ||
      eventDate ||
      finishTime ||
      services?.length;
    if (fetchFormSettingsOnInputChange && hasSearchFormChanged && !store?.id) {
      fetchFormSettings?.();
    }
  }, [userInput]);

  function handleChange(value) {
    setUserInput?.(value);
  }

  async function handleSubmit(e, useExternalData = false) {
    if (!dataFetched) {
      return;
    }

    const experimentId = userInput?.['experimentId'];

    const dataSource = (useExternalData && e) || userInput;
    const storeSlug = store?.slug;
    const categorySlug = category?.slug;
    const { location, eventDate, finishTime, services } = dataSource;
    const isDataValid =
      storeSlug &&
      categorySlug &&
      location?.coordinates?.length &&
      eventDate &&
      // finishTime &&
      services?.length;

    if (!isDataValid) {
      return;
    }
    const data: any = {
      storeSlug,
      categorySlug,
      location,
      eventDate,
      finishTime,
      services,
    };
    const gclid = localStorage.getItem('gclid');
    if (gclid) {
      data.gclid = gclid;
    }

    if (experimentId) {
      data.experimentId = experimentId;
    }

    try {
      let heapId = localStorage.getItem('heapId');
      if (!heapId) {
        heapId = generateId();
        localStorage.setItem('heapId', heapId);
      }
      const heap = window['heap'];
      if (heap) {
        heap.identify(heapId);
      }
      data.heapId = heapId;
    } catch (error) {
      console.log('[SearchFormContainer] heap error', error);
    }

    const url = window.location.href;
    data.landingPage = url;

    if (searchId) {
      data.previousSearch = searchId;
      for (const field of ['gclid', 'heapId', 'landingPage']) {
        if (data[field]) {
          delete data[field];
        }
      }
    }

    if (!onSubmit) {
      const res = await post('/v1/quotes/', data);
      if (quoteData.quote?._id) {
        resetQuoteData();
      }
      // remove glcid once a search is successful
      localStorage.removeItem('gclid');
      const search: any = res.data.search;
      let url = '/quotes/';
      url += search.id;

      localStorage.setItem('searchId', search.id);
      return history.push(url);
    }
    if (useExternalData) {
      setUserInput?.(e);
    }
    onSubmit?.(data);
  }

  return (
    (!!Component && (
      <>
        <Component
          onChange={handleChange}
          userInput={userInput}
          onSubmit={handleSubmit}
          {...externalProps}
        />
      </>
    )) ||
    (typeof children === 'function' && children(userInput)) ||
    null
  );
};
export default SearchFormContainer;
