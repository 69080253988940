import React, { createContext, useContext, useState, useCallback } from 'react';

interface SnackbarContextType {
  showSnackbar: (message: string, options?: SnackbarOptions) => void;
}

interface SnackbarOptions {
  duration?: number;
  type?: 'success' | 'error' | 'info' | 'warning';
}

interface SnackbarProviderProps {
  children: React.ReactNode;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [snackbar, setSnackbar] = useState<{
    message: string;
    visible: boolean;
    type: string;
  }>({
    message: '',
    visible: false,
    type: 'info',
  });

  const showSnackbar = useCallback(
    (message: string, options?: SnackbarOptions) => {
      setSnackbar({
        message,
        visible: true,
        type: options?.type || 'info',
      });

      setTimeout(() => {
        setSnackbar((prev) => ({ ...prev, visible: false }));
      }, options?.duration || 3000);
    },
    []
  );

  const getBackgroundColor = (type: string) => {
    switch (type) {
      case 'success':
        return 'bg-green-500';
      case 'error':
        return 'bg-red-500';
      case 'warning':
        return 'bg-yellow-500';
      default:
        return 'bg-blue-500';
    }
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      {snackbar.visible && (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50">
          <div
            className={`${getBackgroundColor(
              snackbar.type
            )} text-white px-6 py-3 rounded-md shadow-lg`}
          >
            {snackbar.message}
          </div>
        </div>
      )}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
