import {
  faBan,
  faCheck,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';
import { faHandSparkles, faRedo } from '@fortawesome/pro-regular-svg-icons';
import {
  faBolt,
  faCheckCircle,
  faFire,
  faMinusCircle,
  faStar,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import classnames from 'classnames';
import React from 'react';
import { GradientText } from '../GradientText/GradientText';
import { Img, ImgSizes } from '../Img/Img';
import { ListItem } from '../ListItem/ListItem';
import { ShowIf } from '../ShowIf/ShowIf';
import { IDropShadow } from '../types/dropShadow';
import { IRoundedCorners } from '../types/roundedCorners';

interface IProductCardProps extends IDropShadow, IRoundedCorners {
  coverImg: string;
  coverImgSize?: ImgSizes;
  coverText?: string;
  /**
   * black and transparent overlay that starts from the bottom of cover image
   * */
  overlay?: boolean;
  name: string;
  rating?: number;
  profileImg: string;
  price: number;
  deposit: number;
  depositAltContent?: React.ReactNode;
  status?:
    | 'inDemand'
    | 'unavailable'
    | 'covidTrained'
    | 'requested'
    | 'offered'
    | 'booked'
    | 'cancelled';
  currency?: string;
  totalReviews?: number;
  id?: string;
  data?: any;
  layout?: 'stacked' | 'sideBySide';
  featured?: boolean;
  responsiveCoverImg?: boolean;
  coverImgScreenSizes?: any;
  avatarStaticResponsiveImgSize?: number;
  hideReview?: boolean;
  coverImgAspectRatio?: number;
  vaccinated?: boolean;
  onClick?: (e?: any, id?: string, data?: any) => void;
}

export const ProductCard: React.FC<
  React.PropsWithChildren<IProductCardProps>
> = ({
  coverImg,
  overlay,
  shadow = 'none',
  coverText,
  rounded = 'xl',
  status,
  profileImg,
  rating,
  price,
  deposit,
  currency = '$',
  totalReviews,
  name,
  id,
  data,
  layout = 'stacked',
  featured,
  responsiveCoverImg,
  coverImgScreenSizes,
  avatarStaticResponsiveImgSize,
  coverImgAspectRatio = 1.2,
  hideReview,
  depositAltContent,
  vaccinated,
  onClick,
}) => {
  function handleClick(e) {
    onClick?.(e, id, data);
  }
  return (
    <div
      onClick={handleClick}
      className={classnames(
        'cursor-pointer md:hover:opacity-75 w-full flex flex-col',
        {
          [`rounded-${rounded}`]: rounded && typeof rounded === 'string',
          rounded: rounded === true,
          [`shadow-${shadow}`]: shadow && typeof shadow === 'string',
          shadow: shadow === true,
          'opacity-75': status === 'unavailable',
          'grid grid-cols-2 items-stretch': layout === 'sideBySide',
          'bg-primary p-[2px]': featured,
        }
      )}
    >
      <section className={classnames('relative')}>
        <div
          className={classnames(
            {
              [`rounded-t-xl`]:
                rounded && typeof rounded === 'string' && layout === 'stacked',
              'rounded-t': rounded === true && layout === 'stacked',
              [`rounded-l-xl`]:
                rounded &&
                typeof rounded === 'string' &&
                layout === 'sideBySide',
              'rounded-l': rounded === true && layout === 'sideBySide',
            },
            'overflow-hidden'
          )}
        >
          <div
            className={classnames('pb-2', {
              // className={classnames('p-2', {
              // 'bg-gray-200': !featured,
              'bg-black': featured,
            })}
          >
            <div
              className="relative overflow-hidden rounded-t-xl"
              // className="relative overflow-hidden rounded-xl"
              style={{ paddingTop: `${coverImgAspectRatio * 100}%` }}
            >
              <Img
                src={coverImg}
                grayScale={['unavailable', 'cancelled'].includes(status || '')}
                // size={coverImgSize}
                responsive={responsiveCoverImg}
                screenSizes={coverImgScreenSizes}
                hideOnError
                className="absolute top-0 left-0 w-full h-full"
                imgClassName="absolute top-0 left-0 w-full h-full"
              />
              {overlay && (
                <div className="absolute bottom-0 left-0 flex items-center w-full p-3 opacity-50 h-1/2 bg-gradient-to-b from-transparent to-black"></div>
              )}
            </div>
          </div>
          {!!coverText && (
            <div className="absolute top-0 left-0 flex items-center w-full h-full p-3">
              <div className="px-2 py-1 ml-auto text-white bg-white rounded-md shadow-sm bg-opacity-40">
                {coverText}
                <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
              </div>
            </div>
          )}
          {featured && (
            <section className="absolute top-0 left-0 flex justify-end w-full p-2 mt-1">
              <span className="inline-flex items-center px-3 py-2 mr-1 mt-1 text-xs text-white rounded-md shadow-sm bg-primary">
                Recommended
              </span>
            </section>
          )}
        </div>
        <div className="absolute bottom-0 left-0 pb-3">
          <div className="transform -translate-x-1">
            <ProductStatusLabel status={status} />
          </div>
        </div>
      </section>
      <footer
        className={classnames('p-2  overflow-hidden flex-grow', {
          // 'bg-gray-200': !featured,
          'bg-black': featured,
          [`rounded-b-xl`]: rounded && rounded === 'xl' && layout === 'stacked',
          'rounded-b': rounded === true && layout === 'stacked',
          [`rounded-r-xl`]:
            rounded && typeof rounded === 'string' && layout === 'sideBySide',
          'rounded-r': rounded === true && layout === 'sideBySide',
          'text-white bg-black': featured,
        })}
      >
        <section>
          <ListItem
            leftIconClassName="-mr-1"
            leftIcon={
              <div style={{ width: 30, height: 30 }}>
                <Img
                  src={profileImg}
                  size="square"
                  position="top"
                  grayScale={['unavailable', 'cancelled'].includes(
                    status || ''
                  )}
                  responsive={!!avatarStaticResponsiveImgSize}
                  staticResponsiveSize={avatarStaticResponsiveImgSize}
                  hideOnError
                  round
                />
              </div>
            }
            titleClassName={classNames(
              'font-medium leading-tight text-gray-700 text-xs capitalize',
              {
                'text-white': featured,
                'text-gray-700': !featured,
              }
            )}
            title={name}
            subTitle={
              <div
                className={classNames('text-xs ', {
                  'text-white': featured,
                  'text-gray-700': !featured,
                })}
              >
                <ShowIf condition={!hideReview}>
                  <div>
                    {!!rating && rating}
                    {!!rating && (
                      <FontAwesomeIcon
                        icon={faStar}
                        className={classnames('mx-1 inline-block', {
                          'text-review-star': ![
                            'unavailable',
                            'cancelled',
                          ].includes(status || ''),
                          'text-gray-400': [
                            'unavailable',
                            'cancelled',
                          ].includes(status || ''),
                        })}
                      />
                    )}
                    {!!totalReviews && (
                      <span
                        className={classNames({
                          'text-gray-600': !featured,
                          'text-gray-200': featured,
                        })}
                      >
                        ({totalReviews || 'new'})
                      </span>
                    )}
                  </div>
                </ShowIf>
                {vaccinated && (
                  <div className="pt-px font-medium text-green-600 uppercase">
                    <FontAwesomeIcon icon={faCheckCircle} /> Vaccinated
                  </div>
                )}
                {/* <div className="flex items-center pt-px font-medium text-green-600 uppercase">
                  <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />{' '}
                  <span className="leading-none">COVID-19 Vaccine Exempt</span>
                </div> */}
              </div>
            }
            mb="0"
            pb="0"
            rightIcon={
              <FontAwesomeIcon
                icon={faChevronRight}
                className="text-sm text-gray-400"
              />
            }
          />
        </section>

        <section className="pt-2 text-sm">
          <div
            className={classNames('font-medium leading-tight', {
              'text-white': featured,
              'text-gray-700': !featured,
            })}
          >
            {currency}
            {price?.toLocaleString()} Total
          </div>
          <div className="leading-none text-gray-400">
            <ShowIf
              condition={!depositAltContent}
              elseTemplate={depositAltContent}
            >
              deposit of {currency}
              {deposit?.toLocaleString()}
            </ShowIf>
          </div>
        </section>
      </footer>
    </div>
  );
};

const statusIconMapping = {
  inDemand: faFire,
  unavailable: faMinusCircle,
  covidTrained: faHandSparkles,
  requested: faRedo,
  offered: faBolt,
  booked: faCheck,
  cancelled: faBan,
};
const statusLabelClassName =
  'px-3 py-1 rounded-l-lg rounded-r-full flex items-center capitalize';

const statusLabelMapping = {
  inDemand: 'In Demand',
  unavailable: 'Unavailable',
  covidTrained: 'COVID Trained',
  requested: 'Requested',
  offered: 'Instant Book',
  booked: 'booked',
  cancelled: 'cancelled',
};
const ProductStatusLabel: React.FC<
  React.PropsWithChildren<{ status?: string }>
> = ({ status }) => {
  if (!status || !statusLabelMapping[status]) {
    return null;
  }
  return (
    <div
      className={classnames(statusLabelClassName, {
        'bg-red-500 text-white': status === 'inDemand',
        'bg-gray-800 text-white': status === 'unavailable',
        'bg-teal-500 text-white': status === 'covidTrained',
        'bg-secondary-1 text-white': status === 'requested',
        'bg-green-500 text-white': status === 'offered',
        'bg-purple-500 text-white': status === 'booked',
        'bg-gray-200 text-red-500': status === 'cancelled',
      })}
    >
      <FontAwesomeIcon icon={statusIconMapping[status]} className="mr-1" />
      {statusLabelMapping[status]}
    </div>
  );
};
