import React, { useEffect } from 'react';

// Create a new Heap component
const HeapAnalytics: React.FC<{ appId: string }> = ({ appId }) => {
  useEffect(() => {
    // Initialize Heap
    window.heap = window.heap || [];
    window.heap.load = function (appId: string) {
      window.heap.appid = appId;
      window.heap.config = {};

      let heapScript = document.createElement('script');
      heapScript.type = 'text/javascript';
      heapScript.async = true;
      heapScript.src = 'https://cdn.heapanalytics.com/js/heap-' + appId + '.js';

      let firstScript = document.getElementsByTagName('script')[0];
      if (firstScript && firstScript.parentNode) {
        firstScript.parentNode.insertBefore(heapScript, firstScript);
      }
    };
    window.heap.load(appId);
  }, [appId]);

  return null;
};

export default HeapAnalytics;
